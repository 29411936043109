import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Font } from "../../layout/GlobalStyles/Font";
import BackgroundTile from "./pixel-space-tile.png";

export const Root = styled.div`
    background: ${Color.Neutral100};
    background-image: radial-gradient(${Color.Accent10}, transparent 40%), url(${BackgroundTile});
    background-position: 50% 100%;
    background-repeat: repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    color: ${Color.White100};
`;

export const FormContainer = styled.div`
    background: ${Color.White10};
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    backdrop-filter: blur(8px);
    display: grid;
    grid-row-gap: 32px;
    box-sizing: border-box;
    width: 300px;
    padding: 32px;
    border-radius: 4px;
    text-align: center;
`;

export const FormFieldWrapper = styled.form`
    display: grid;
    grid-row-gap: 16px;
`;

export const Heading = styled.h1`
    font-family: ${Font.SpaceGrotesk};
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;
`;
